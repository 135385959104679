:root {
    --color-white: #ffffff;
    --color-black: #000000;
    --color-green: #00a066;
    --color-gray: #5B5B5B;
    --color-light-green: #DFFFF3;
    --color-lime: #C9FF9E;
    --color-silver: #C8C8C8;
    --color-light-gray: #808082;
    --color-dark-white: #EDF1F7;
    --color-very-light-green: #F0FFFA;
    --color-very-light-gray: #8E8E8E;
    --color-elephant: #888F9B;
    --color-dark-gray: #5C5C5C;
    --color-red: #F00;
    --color-dead: #EEF1F7;
    --color-cyan: #00BAAF;
    --color-blue-is-warmest-color: #3CACDF;
}

body {
    margin: 0;
    padding: 0;
    font-family: Rubik;
    background: var(--color-dead);
}

@font-face {
    font-family: 'Rubik';
    src: url('../font/rubik/Rubik-Regular.eot');
    src: url('../font/rubik/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
    url('../font/rubik/Rubik-Regular.woff2') format('woff2'),
    url('../font/rubik/Rubik-Regular.woff') format('woff'),
    url('../font/rubik/Rubik-Regular.svg#Rubik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RubikBold';
    src: url('../font/rubik/Rubik-Black.eot');
    src: url('../font/rubik/Rubik-Black.eot?#iefix') format('embedded-opentype'),
    url('../font/rubik/Rubik-Black.woff2') format('woff2'),
    url('../font/rubik/Rubik-Black.woff') format('woff'),
    url('../font/rubik/Rubik-Black.svg#Rubik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IRANSans';
    font-style: normal;
    font-weight: normal;
    src: url('../font/iransans/eot/IRANSansWeb(FaNum).eot');
    src: url('../font/iransans/eot/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'),
    url('../font/iransans/woff2/IRANSansWeb(FaNum).woff2') format('woff2'),
    url('../font/iransans/woff/IRANSansWeb(FaNum).woff') format('woff'),
    url('../font/iransans/ttf/IRANSansWeb(FaNum).ttf') format('truetype');
}

a, a:link, a:hover {
    text-decoration: unset!important;
}

.hidden-scrollbar::-webkit-scrollbar {
    width: 5px;
}

.hidden-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

.hidden-scrollbar::-webkit-scrollbar-thumb {
    background: var(--color-green);
    border-radius: 2px;
}

.hidden-scrollbar::-webkit-scrollbar-thumb:hover {
    background: var(--color-green);
    border-radius: 2px;
}

.ant-table-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.ant-table-body::-webkit-scrollbar-track {
    background: transparent;
}

.ant-table-body::-webkit-scrollbar-thumb {
    background: var(--color-green);
    border-radius: 3px;
}

/* BS */

.big-button {
    min-width: 138px;
    height: 43px;
    justify-content: center;
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

.text-nowrap {
    white-space: nowrap;
}

.d-inline-block {
    display: inline-block;
}

.d-flex {
    display: flex;
}

.d-block {
    display: block;
}

.flex-wrap {
    flex-wrap: wrap;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: end;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: end;
}

.justify-content-start {
    justify-content: start;
}

.h-100 {
    height: 100%;
}

.mh-100vh {
    min-height: 100vh;
}

.list-unstyled {
    list-style: none;
}

.m-0 {
    margin: 0;
}

.p-0 {
    padding: 0;
}

.w-100 {
    width: 100%;
}

.h-100-vh {
    height: 100vh;
}

.mh-100 {
    min-height: 100%;
}

.img-fluid {
    width: 100%;
}

.text-center {
    text-align: center;
}

/* Buttons */

.light-green-button {
    background: var(--color-light-green);
    border: unset!important;
}

.white-button {
    background: var(--color-white);
    border: unset!important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.dark-green-button {
    background: var(--color-green);
    border: unset!important;
}

.dark-green-button-border {
    background: transparent!important;
    border: 1px solid var(--color-green)!important;
    border-radius: 30px;
}

/* Colors */

.black-color {
    color: black!important;
}

.dark-green-color {
    color: var(--color-green)!important;
}

.white-color {
    color: var(--color-white)!important;
}

.gray-color {
    color: var(--color-gray)!important;
}

.red-color {
    color: var(--color-red)!important;
}


/* Reset */

.ant-table {
    background: transparent!important;
}

.ant-menu .ant-menu-item .ant-menu-item-icon +span,
.ant-menu .ant-menu-submenu-title .ant-menu-item-icon +span {
    margin-inline-start: 23px!important;
    font-weight: 600;
}

.ant-menu-root > .ant-menu-item,
.ant-menu-submenu .ant-menu-submenu-title {
    padding: 30px!important;
}

.ant-menu .ant-menu-submenu-arrow {
    right: 30px!important;
}

.ant-menu-submenu-selected i {
    color: var(--color-lime)!important;
}

.ant-menu-submenu .ant-menu-sub .ant-menu-item {
    padding: 20px 60px!important;
}

.ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover,
.ant-menu-light .ant-menu-item-selected,
.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: var(--color-white)!important;
}

.ant-menu-submenu-active .ant-menu-submenu-title span,
.ant-menu-item.ant-menu-item-selected {
    color: var(--color-lime)!important;
}

.ant-menu-submenu-active .ant-menu-submenu-title,
.ant-menu-item.ant-menu-item-active {
    background: var(--color-green)!important;
}

.ant-menu-submenu-open .ant-menu-submenu-title span {
    color: var(--color-lime)!important;
}

.ant-tabs .ant-tabs-tab-btn:active {
    color: var(--color-gray)!important;
}

.ant-tabs .ant-tabs-ink-bar {
    background: var(--color-green)!important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-gray)!important;
    font-weight: 600;
}

.ant-tabs-tab-btn {
    font-size: 20px;
}

.ant-tabs-nav-wrap {
    border-bottom: 1px solid var(--color-silver);
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 4px!important;
    border-radius: 10px;
}

.ant-tabs-tab {
    color: var(--color-light-gray)!important;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after,
.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after,
.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: transparent!important;
}

.ant-steps-item-container {
    display: flex;
    align-items: center;
}

.ant-steps-item-icon {
    width: 22px!important;
    height: 22px!important;
    background: var(--color-silver)!important;
    border: 2px solid transparent;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background: transparent!important;
    border: 2px solid var(--color-green)!important;
}

.ant-steps-item-title {
    color: var(--color-light-gray)!important;
    font-size: 20px!important;
    font-weight: normal!important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-title,
.ant-steps .ant-steps-item-finish .ant-steps-item-title {
    color: var(--color-green)!important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    width: 15px!important;
    height: 15px!important;
    background: var(--color-green)!important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon,
.ant-menu-light .ant-menu-item-selected {
    background: var(--color-green);
}

.ant-steps-item-finish {
    background: var(--color-very-light-green);
}

span.ant-steps-icon {
    display: none!important;
}

.ant-steps {
    border-bottom: 1px solid var(--color-silver);
}

.ant-steps-item {
    flex: unset!important;
    width: 100%;
    justify-content: center!important;
    display: flex!important;
    height: 170px;
    align-items: center;
    position: relative;
}

.ant-steps-item-accounts .ant-steps-item {
    height: 120px!important;
}

.ant-steps-item[content="b"]::after,
.ant-steps-item[content="b"]::before {
    content: "";
    width: 1px;
    height: 50px;
    background: var(--color-silver);
}

.ant-steps-item-accounts .ant-steps-item[content="b"]::after {
    display: none;
}

.ant-steps-item[content="b"]::after {
    position: absolute;
    right: 0;
}

.ant-steps-item[content="b"]::before {
    position: absolute;
    left: 0;
}

.ant-steps-item-active[content="b"]::before,
.ant-steps-item-finish[content="b"]::before{
    display: none;
}

.ant-steps-item-finish[content="b"]::after {
    display: none;
}

.ant-select-selector {
    border-radius: 30px!important;
}

.ant-input-group .ant-input-group-addon:first-child {
    border-radius: 30px 0 0 30px;
}

.ant-input-group >.ant-input:last-child {
    border-radius: 0 30px 30px 0!important;
}

.ant-input-affix-wrapper {
    border-radius: 30px;
}

span.ant-input-suffix {
    font-size: 20px;
    color: var(--color-very-light-gray)
}

.ant-upload-hint {
    color: var(--color-very-light-gray)!important;
    font-size: 20px!important;
    margin: 0!important;
    padding: 0!important;
}

.ant-upload-drag-icon {
    margin: 0!important;
    padding: 0!important;
}

.anticon-upload {
    font-size: 24px!important;
    color: var(--color-very-light-gray)!important;
}

.ant-upload.ant-upload-btn {
    padding: 50px 0!important;
}

.ant-upload.ant-upload-drag {
    background: #EEEEEE;
    border: unset!important;
}

.ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-1l3pdbx).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background: transparent!important;
}

.ant-modal .ant-modal-content {
    padding: 0!important;
    overflow: hidden;
}

.ant-modal-body {
    padding: 15px;
}

.account-steps {
    border-bottom: unset!important;
    flex-direction: row!important;
}

.swiper-scrollbar.swiper-scrollbar-horizontal {
    opacity: 1!important;
}

.swiper-scrollbar-drag {
    background: #c5c5c5!important;
}

.ant-switch.ant-switch-checked {
    background: var(--color-green)!important;
}

.ant-dropdown-menu-title-content {
    font-size: 15px!important;
}

.swiper-slide:last-child > div {
    border: unset!important;
}

.ant-dropdown-menu-title-content {
    font-size: 18px;
}

.ant-timeline .ant-timeline-item-head {
    background: transparent!important;
}

.ant-upload-wrapper .ant-upload-drag {
    height: auto!important;
}

.links-modal .ant-modal-footer {
    padding: 20px;
}

.crypto-address {
    color: var(--color-green);
    font-weight: bold;
    margin-top: 50px;
    display: block;
}

.swiper-pagination-bullet {
    background: white!important;
}

.swiper-button-prev {
    background: url("../img/accounts/prev.svg") no-repeat;
}

.swiper-button-next {
    background: url("../img/accounts/next.svg") no-repeat;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    display: none;
}

.ant-ribbon-text {
    font-size: 18px!important;
}

/* RTL */

.rtl .ant-menu .ant-menu-submenu-arrow {
    right: unset!important;
    left: 30px;
}

.rtl .ant-steps-item[content="b"]::before {
    left: unset!important;
    right: 0;
}

.ant-steps-rtl .ant-steps-item[content="b"]::after {
    right: unset;
    left: 0;
}

.rtl .swiper-button-prev {
    background: url("../img/accounts/next.svg") no-repeat;
}

.rtl .swiper-button-next {
    background: url("../img/accounts/prev.svg") no-repeat;
}

@media all and (min-width: 992px){
    .d-md-flex {
        display: flex;
    }
    .mh-md-100 {
        min-height: 100%;
    }
    .mh-md-100vh {
        min-height: 100vh;
    }
    .ant-tabs-tab-btn {
        font-size: 18px;
    }
}

@media all and (max-width: 991px) {
    .ant-ribbon-text {
        font-size: inherit!important;
    }
    .ant-dropdown-menu-title-content,
    .ant-menu-title-content {
        font-size: 14px;
    }
    .ant-tabs-tab-btn,
    .ant-upload-hint,
    .ant-steps-item-title {
        font-size: 15px!important;
    }
    .deposit-steps,
    .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
        display: none!important;
    }
    .deposit-select {
        margin-top: 15px;
    }
    .ant-col-full {
        width: 100%;
    }
    span.ant-input-suffix {
        font-size: 16px;
    }
    .ant-steps-item {
        height: auto;
        padding: 15px 0;
    }
    .ant-steps.ant-steps-vertical >.ant-steps-item .ant-steps-item-content {
        min-height: auto;
    }
}