.PanelContainer {
    width: 100%;
    display: flex;
}

.AuthContainer {
    height: 100vh;
}

.AuthWrapper {
    height: 100%;
}

.LoginHeader {
    padding: 30px 0 0 0;
}

.Main {
    width: 100%;
}

.MainContent {
    width: calc(100% - 345px);
    padding-bottom: 50px;
}

.Main h1 {
    color: var(--color-green);
    font-size: 24px;
    font-weight: 600;
    margin-top: 35px;
}

.Main h2 {
    color: var(--color-gray);
    font-size: 24px;
    font-weight: 600;
}

.PanelHeader {
    background: #FFF;
    box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.10);
    height: 105px;
    padding: 0 70px;
}

.SideBarWrapper {
    width: 345px;
    position: relative;
}

.SideBar {
    background: linear-gradient(180deg, #00B775 0%, #00BAAF 100%);
    width: 345px;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

.SideBarMenu {
    background: transparent!important;
    color: white;
    font-size: 16px;
    width: 100%;
    border: unset!important;
}

.SideBarLogo {
    background: var(--color-green) url("../img/logo-w-green.svg") no-repeat;
    background-size: cover;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
}

.HeaderButton {
    font-size: 19px;
    color: var(--color-gray);
    font-weight: bold;
}

.TradersBox {
    background: #7E7E7E;
    border-radius: 7px;
    transition: .5s all ease;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.TradersBoxTop {
    background: #FFB743;
}

.TradersBox:hover {
    box-shadow: 0px 0px 20px 5px #3EBAAFCC;
    cursor: pointer;
}

.TraderBox {
    margin-top: 40px;
}

.TraderBox h6 {
    font-size: 18px;
    margin: 0 0 40px 0;
    text-align: center;
}

.TradersBox span {
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    display: block;
    word-break: break-all;
    padding: 20px;
    text-transform: uppercase;
}

.PanelWrapper {
    padding: 0 70px;
}

.PanelBox {
    border-radius: 10px;
    background: var(--color-white);
    box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.10);
    overflow: hidden;
}

.PanelBox h4 {
    font-size: 20px;
    color: var(--color-gray);
    font-weight: normal;
    margin-top: 0;
    white-space: nowrap;
}

.PanelBox h3 {
    font-size: 20px;
    color: var(--color-green);
    font-weight: bold;
}

.PanelBox h4 i {
    font-weight: bold;
}

.PanelBoxPadding {
    padding: 30px;
}

.PanelWrapperPadding {
    padding: 70px;
}

.QuickLinksIconBox {
    border-radius: 10px 0px 0px 10px;
    background: linear-gradient(360deg, #DFFFF3 0%, rgba(223, 255, 243, 0.00) 100%);
    width: 85px;
    height: 97px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.QuickLinksIconBox img {
    width: 29px;
}

.QuickLinks h5 {
    font-size: 20px;
    color: var(--color-gray);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 85px);
    font-weight: normal;
    text-align: center;
    padding: 0 5px;
}

.PanelBoxWrapper {
    border-radius: 5px;
    background: linear-gradient(270deg, #EEF1F7 19.92%, rgba(238, 241, 247, 0.00) 79.84%);
    width: 100%;
    padding: 5px;
}

.OverviewTable thead tr th,
.LinksTable thead tr th,
.LinksTable thead tr td {
    color: var(--color-gray)!important;
    background: transparent!important;
    border: unset!important;
}

.OverviewTable thead tr td,
.OverviewTable tbody tr td,
.LinksTable tbody tr td {
    background: transparent!important;
    border: unset!important;
}

.LinksTable thead tr td::before {
    display: none!important;
}

.OverviewTable tbody tr td bdi {
    color: var(--color-gray);
    font-size: 14px;
}

.DashboardSection h6 {
    color: var(--color-gray);
    font-size: 22px;
    margin: 30px 0 15px 0;
}

.OverviewTable tbody tr td {
    font-size: 20px;
}

.LinksTable thead tr th,
.LinksTable tbody tr td {
    font-size: 16px;
    color: var(--color-dark-gray);
}

.AccountName {
    white-space: nowrap;
}

.OverviewTag {
    color: var(--color-green);
    background: var(--color-light-green);
    border-radius: 30px;
    font-size: 10px;
    padding: 5px 13px;
    font-weight: bold;
    white-space: nowrap;
}

.PanelWallet span {
    color: var(--color-gray);
    font-size: 28px;
}

.PanelWallet span bdi {
    font-size: 20px;
}

.NewsBox li {
    margin-bottom: 20px;
    padding: 10px;
}

.SwitchTheme {
    margin-right: 22px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SwitchLang {
    margin-right: 45px;
}

.SwitchLang span {
    margin-left: 10px;
}

.HeaderLinks {
    display: flex;
    align-items: center;
}

.HeaderButtons a {
    font-size: 19px;
    margin-left: 30px;
    font-weight: bold;
}

.PanelBoxIcon {
    border-right: 2px solid #DBDBDB;
    margin-right: 30px;
    padding-right: 30px;
}

.WalletList li {
    margin-bottom: 15px;
}

.WalletListAmount {
    font-size: 28px;
    color: var(--color-gray);
}

.PanelBoxWrapper h6 {
    font-size: 22px;
    margin: 0;
    color: var(--color-gray);
    font-weight: normal;
}

.WalletListAmount bdi {
    margin-left: 10px;
    font-size: 22px;
}

.DepositMethod {
    background: var(--color-dark-white);
    padding: 15px;
    border-radius: 10px;
    margin-top: 40px;
}

.DepositItemList {
    width: calc(100% / 5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.DepositItemList > span {
    color: var(--color-dark-gray);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.DepositItemList > bdi {
    color: var(--color-very-light-gray);
    font-size: 14px;
    white-space: nowrap;
}

.DepositHead li span {
    color: var(--color-very-light-gray);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.DepositHead li {
    width: calc(100% / 5);
}

.DepositMethodItem {
    margin: 0;
    padding: 0;
}

.DepositItem {
    background: var(--color-dead);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
}

.DepositMethodItem li {
    background: #FFF;
    padding: 15px 50px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    transition: .5s all ease;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-silver);
    padding: 20px 0;
}

.DepositMethodItem li:last-child {
    border-bottom: unset;
}

.DepositItem h5 {
    color: var(--color-green);
    font-size: 20px;
    margin: 0 auto 0 0;
    font-weight: normal;
}

.DepositItem img {
    margin-right: auto;
}

.DepositMethods > label {
    font-size: 20px;
    margin-right: 15px;
}

.DepositDetails {
    border-top: 1px solid var(--color-silver);
    margin-top: 30px;
}

.DepositMethodButtons {
    margin-top: 20px;
}

.DepositMethodButtons button:last-child {
    margin-left: 25px;
}

.DepositMethodButtons button {
    font-size: 17px;
    font-weight: 500;
    height: auto!important;
}

.DepositMethodForm label {
    font-size: 18px;
    color: var(--color-gray);
    margin-right: 25px;
}

.DepositMethodForm input {
    border-radius: 30px!important;
    font-size: 18px!important;
}

.DepositMethodForm small,
.DepositMethods small {
    font-size: 17px;
    color: var(--color-very-light-gray);
    margin-top: 5px;
    display: block;
}

.DepositNotes {
    font-size: 19px;
    color: var(--color-elephant);
    padding: 18px 29px!important;
}

.DepositNotes h5,
.DepositNotes h6 {
    font-size: 19px;
    margin: 0;
}

.DepositReview li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.DepositReview li span {
    display: flex;
    align-items: center;
    width: 100%;
}

.DepositReview li span::after {
    content: "";
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, var(--color-white), var(--color-green));
    display: inline-block;
    margin: 0 30px;
}

.DepositReview li bdi,
.DepositReview li span {
    white-space: nowrap;
    font-size: 20px;
    color: var(--color-gray)
}

.DepositReview strong {
    color: var(--color-green);
    font-size: 20px;
}

.DepositSuccess {
    text-align: center;
    font-size: 20px;
    color: var(--color-gray);
}

.WithdrawSuccess {
    background: var(--color-green);
    width: 100%;
    height: 172px;
    border-radius: 10px;
}

.WithdrawSuccess span {
    color: var(--color-white);
    font-size: 20px;
    margin-left: 15px;
}

.PanelBoxDetails h6 {
    color: var(--color-green);
    font-size: 20px;
    margin: 0;
    padding: 0;
}

.PanelBoxDetails label {
    font-size: 20px;
    color: var(--color-gray);
    margin-bottom: 10px;
    display: block;
}

.PaymentDetailsUpload small {
    color: var(--color-very-light-gray)!important;
    font-size: 17px;
    margin-top: 10px;
    display: block;
}

.AccountsOverview {
    padding: 25px 30px;
}

.AccountsOverviewBorder {
    border-right: 1px solid var(--color-silver);
}

.AccountsOverview table tr th {
    color: var(--color-green)!important;
    background: transparent!important;
    white-space: nowrap;
    font-size: 15px;
    border-bottom: unset!important;
}

.AccountsOverview table tr td {
    background: transparent!important;
    color: var(--color-dark-gray)!important;
    font-size: 16px;
    border-bottom: unset!important;
}

.AccountsOverviewBox {
    background: var(--color-light-green);
    padding: 25px;
    border-radius: 10px;
}

.AccountsOverviewBox li > span {
    font-size: 15px;
    color: var(--color-green);
    font-weight: bold;
    margin-bottom: 15px;
    display: block;
}

.AccountsOverviewBox bdi {
    display: block;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
    color: var(--color-dark-gray);
}

/*.AccountsOverviewWrapper {
    overflow-x: auto;
    width: 100%;
    display: flex;
}*/

.AccountModalHeader {
    background: var(--color-green);
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 10px 10px;
    padding: 15px 0;
}

.AccountModalHeaderSmall {
    min-height: auto!important;
}

.AccountModalHeader p {
    margin: 0;
    padding: 0;
}

.AccountModalHeader span {
    font-size: 24px;
    color: white;
    font-weight: bold;
}

.AccountModalHeader p {
    font-size: 20px;
    color: white;
}

.AccountModalField label {
    color: var(--color-gray);
    font-size: 20px;
    white-space: nowrap;
    min-width: 150px;
}

.AccountModalReviewCopy {
    margin-left: 30px;
    cursor: pointer;
}

.AccountModalField label[data-disabled="true"] {
    color: var(--color-silver)!important;
}

.AccountModalField {
    display: flex;
    margin-bottom: 30px;
}

.AccountModalFields,
.AccountModalReview {
    width: 620px;
    margin: auto;
}

.AccountModalReviewDownload {
    cursor: pointer;
}

.AccountTable {

}

.AccountTable li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.AccountTable li:nth-child(odd) {
    background: #EEF1F7;
}

.AccountTable li > span {
    color: var(--color-green);
    font-size: 15px;
    font-weight: bold;
    min-width: 220px;
}

.AccountTable li bdi {
    color: var(--color-dark-gray);
    font-size: 16px;
    margin-right: auto;
    word-break: break-all;
    padding: 0 5px;
}

.AccountTable li > div {
    /*cursor: pointer;*/
}

.AccountsOverviewDetails {
    background: var(--color-light-green);
    padding: 30px;
    border-radius: 10px;
    margin-top: 30px;
}

.AccountsOverviewDetails > span {
    color: var(--color-green);
    font-size: 15px;
    font-weight: bold;
}

.AccountsOverviewDetails bdi {
    color: var(--color-dark-gray);
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin-top: 5px;
}

.Platform {
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(246, 246, 246, 0.00) 28.8%, #F6F6F6 100%);
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.PlatformIcon {
    border-radius: 10px;
    background: #DFFFF3;
    padding: 50px;
    display: flex;
    justify-content: center;
    justify-content: center;
    min-height: 150px;
    align-items: center;
    width: 100%;
}

.PlatformText {
    padding: 27px;
    width: 100%;
}

.Platform h6 {
    color: var(--color-green);
    font-size: 26px;
    font-weight: bold;
    margin: 0!important;
    padding: 0;
    text-align: center;
}

.Platform p {
    color: #8E8E8E;
    text-align: center;
    font-size: 18px;
}

.DownloadBox li {
    width: 46px;
    height: 46px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DownloadBox li:nth-child(2) {
    margin: 0 28px;
}

.DownloadBox span {
    font-size: 20px;
    color: var(--color-green);
    font-weight: bold;
    text-align: center;
    display: block;
    margin: 20px 0;
}

.ResultBox {
    border-top: 1px solid var(--color-silver);
    margin-top: 30px;
    padding-top: 25px;
}

.ResultBox h5 {
    font-size: 20px;
    color: var(--color-green);
    margin: 0 0 20px 0;
}

.ResultBox span {
    color: var(--color-green);
    font-size: 15px;
    display: block;
    margin-bottom: 10px;
    word-break: break-word;
    text-align: center;
}

.ResultBox bdi {
    color: var(--color-dark-gray);
    font-size: 16px;
    display: block;
    word-break: break-word;
    text-align: center;
}

.AccountManagerModal {
    padding: 0 20px;
}

.AccountManagerModal p {
    margin: 0;
    font-size: 20px;
    color: var(--color-dark-gray);
}

.AccountManagerSocial ul {
    margin-top: 20px!important;
}

.AccountManagerSocial li {
    border-radius: 100px;
    background: var(--color-light-green);
    margin-bottom: 20px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
}

.AccountManagerSocial li span {
    color: #007A4E;
    font-size: 20px;
    margin-left: 20px;
}

.Preferences {
    margin-bottom: 5px;
}

.Preferences > span {
    margin-left: 10px;
}

.HelpDeskEmpty {
    margin: 100px 0
}

.HelpDeskEmpty span {
    display: block;
    width: 100%;
    color: var(--color-gray);
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
}

.ChangeForm {
    padding: 0 50px;
}

.ChangeForm label,
.TWoFaForm label {
    color: var(--color-gray);
    font-size: 20px;
}

.AccountVerified {
    display: flex;
    align-items: center;
    margin-bottom: 55px;
}

.AccountVerified span {
    color: var(--color-gray);
    font-size: 18px;
}

.PanelBoxTicket {
    border-radius: 10px;
    background: var(--color-dead);
    padding: 60px 40px;
}

.adminDot {
    width: 16px!important;
    height: 16px!important;
    background: var(--color-silver);
    display: block;
    border-radius: 50%;
}

.userDot {
    width: 16px!important;
    height: 16px!important;
    border: 2px solid var(--color-silver);
    background: var(--color-dead);
    display: block;
    border-radius: 50%;
}

.userDot time, .adminDot time {
    color: var(--color-very-light-gray);
    font-size: 14px;
    position: absolute;
    left: -110px;
    width: 100px;
}

.TicketAuthorName {
    font-size: 20px;
    color: var(--color-green);
    margin-left: 20px;
}

.TicketText {
    padding-left: 55px;
}

.TicketText p {
    color: var(--color-dark-gray);
    font-family: Rubik;
    font-size: 16px;
    line-height: 23px;
}

.TicketUser {
    padding-left: 30px;
}

.TicketAuthor {
    border-radius: 10px;
    background: #FFF;
    padding: 14px 21px;
    margin-left: 80px;
}

.replyDot {
    width: 16px!important;
    height: 16px!important;
    border: 2px solid var(--color-green);
    background: var(--color-dead);
    display: block;
    border-radius: 50%;
}

.TicketReply {
    margin-left: 80px;
}

.TicketTimeline {
    margin-left: 100px;
}

.TicketReply textarea,
.TicketReply textarea:hover {
    border: 1px solid var(--color-green);
    height: 135px;
    resize: none;
    background: white!important;
}

.HelpDeskForm label,
.IBForm label {
    font-size: 20px;
    color: var(--color-gray);
    margin-right: 32px;
    display: block;
}

.HelpDeskForm small {
    font-size: 17px;
    color: var(--color-very-light-gray);
    display: block;
    margin-top: 5px;
}

.HelpDeskForm textarea {
    min-height: 182px;
}

.HelpDeskTable table thead tr th {
    color: var(--color-very-light-gray)!important;
    font-size: 15px!important;
    border: unset!important;
}

.HelpDeskTable table tbody tr td {
    color: var(--color-dark-gray)!important;
    font-size: 18px!important;
    border: unset!important;
}

.HelpDeskTable table thead tr th {
    background: transparent!important;
}

.HelpDeskTable table tbody tr td a {
    color: var(--color-green);
}

.TicketAttachment {
    margin-top: 20px;
    background: #f4f7fb;
    padding: 10px;
    border-radius: 5px;
}

.TicketAttachment strong {
    margin-bottom: 10px;
    display: block;
}

.TicketAttachmentList {
    color: var(--color-green);
    border: 1px solid;
    border-radius: 3px;
    margin-right: 10px;
    padding: 2px 5px;
}

.PerformanceItem span {
    font-size: 20px;
    display: block;
    width: 100%;
}

.PerformanceDashboardItem:nth-child(1) span {
    color: var(--color-green);
}

.PerformanceDashboardItem:nth-child(1) bdi {
    color: var(--color-green);
}

.PerformanceDashboardItem:nth-child(2) span {
    color: var(--color-cyan);
}

.PerformanceDashboardItem:nth-child(2) bdi {
    color: var(--color-cyan);
}

.PerformanceDashboardItem:nth-child(3) span {
    color: var(--color-gray);
}

.PerformanceDashboardItem:nth-child(3) bdi {
    color: var(--color-gray);
}

.PerformanceItem bdi {
    font-size: 20px;
    font-weight: bold;
    display: block;
    width: 100%;
    text-transform: uppercase;
}

.PerformanceItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.PerformanceDashboardItem .PerformanceItem > div {
    padding-left: 40px;
}

.PerformanceDashboardItem:nth-child(1) .PerformanceItem > div {
    border-left: 2px solid var(--color-green);
}

.PerformanceDashboardItem:nth-child(2) .PerformanceItem > div {
    border-left: 2px solid var(--color-cyan);
}

.PerformanceDashboardItem:nth-child(3) .PerformanceItem > div {
    border-left: 2px solid var(--color-gray);
}

.PerformanceDate span {
    color: var(--color-green);
    font-size: 16px;
    margin-right: 20px;
}

.PerformanceDate bdi,
.PerformanceDate strong {
    color: var(--color-dark-gray);
    font-size: 16px;
}

.PerformanceDate strong {
    margin-left: 20px;
}

.PerformanceSetting {
    margin-left: 20px;
}

.PerformanceSetting::before {
    content: "";
    background: var(--color-silver);
    width: 1px;
    height: 28px;
    display: block;
    margin-right: 20px;
}

.AuthSplash {
    background: linear-gradient(180deg, #00B775 0%, #00BAAF 100%);
    min-height: 100%;
    width: 100%;
}

.AuthRegSplash {
    background: white;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.AuthLoginSplash {
    position: relative;
    z-index: 1;
}

.AuthRegSplash::before,
.AuthLoginSplash::before {
    content: "";
    background: url("../img/logo-w.svg") no-repeat center left;
    background-size: contain;
    position: absolute;
    width: 100%;
    height: 100%;
}

.AuthRegSplash img {
    position: relative;
    width: 100%;
}

.AuthLoginSplash::before {
    opacity: .4;
    z-index: -1;
}

.AuthSlogan {
    margin-bottom: 50px;
}

.AuthSlogan span {
    color: white;
    display: block;
    font-size: 47px;
    font-weight: 600;
}


.AuthSlogan bdi {
    color: white;
    display: block;
    font-size: 25px;
    font-weight: 600;
    padding: 0 50px;
}

.AuthSider {
    width: 50%;
    display: block;
    min-height: 100%;
}

.AuthSider60 {
    width: 60%;
    display: block;
    min-height: 100%;
}

.AuthSider40 {
    width: 40%;
    display: block;
    min-height: 100%;
}

.AuthForm {
    padding: 0 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    background: white;
    flex-wrap: wrap;
}

.AuthForm h6 {
    color: var(--color-green);
    font-size: 30px;
    margin: 45px 0 30px 0;
}

.AuthForm label,
.AuthForm a {
    color: var(--color-dark-gray);
    font-size: 15px;
}

.AuthNotRegister {
    border-top: 1px solid var(--color-silver);
    padding-top: 65px;
    width: 100%;
    margin: 65px 0 20px 0;
}

.AuthNotRegister h6 {
    color: var(--color-dark-gray);
    font-size: 30px;
    font-weight: bold;
    margin: 0;
}

.AuthNotRegister > span {
    color: var(--color-dark-gray);
    font-size: 20px;
    display: block;
    margin-top: 10px;
}

.AuthNotRegister p {
    color: var(--color-very-light-gray);
    line-height: 20px;
    margin: 112px 0 0 0;
}

.AuthLogin {
    margin-top: auto;
    width: 100%;
}

.AuthTerms {
    color: var(--color-dark-gray);
    font-size: 16px;
    margin-top: 50px;
}

.AuthTerms div {
    color: var(--color-gray);
}

.AuthTerms a {
    color: var(--color-green)
}

.AuthSlider {
    /*width: 950px;*/
    position: relative;
}

.AuthSlider img {
    max-width: 100%;
    max-height: 600px;
    padding: 15px 0;
}

.LinksTable a {
    color: var(--color-blue-is-warmest-color);
}

.LinksForm label {
    color: var(--color-gray);
    font-size: 20px;
    margin-right: 10px;
}

.LinksForm small {
    color: var(--color-very-light-gray);
    font-size: 17px;
    display: block;
    margin-top: 10px;
}

.DocumentList,
.DocumentList:hover {
    background: var(--color-light-green);
    border-radius: 10px;
    padding: 25px;
    display: flex;
    font-size: 18px;
    color: var(--color-green);
    align-items: center;
    justify-content: flex-start;
}

.DocumentList bdi {
    font-size: 13px;
}

.DocumentVersion bdi {
    text-align: center;
    width: 100%;
    display: block;
}

.Messages a {
    color: var(--color-green)
}

.MessagesEmpty {
    text-align: center;
    margin: 100px 0;
}

.MessagesEmpty span {
    color: var(--color-gray);
    font-size: 20px;
    display: block;
}

.TwoFactorLink {
    background: var(--color-light-green);
    border-radius: 10px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 25px;
}

.TwoFactorItems:nth-child(1) .TwoFactorLink {
    background: var(--color-light-green) url("../img/two-fa/question.svg") no-repeat right bottom!important;
    font-size: 20px;
    color: var(--color-green);
}

.TwoFactorItems:nth-child(2) .TwoFactorLink {
    background: var(--color-dead) url("../img/two-fa/android.svg") no-repeat right bottom!important;
    font-size: 20px;
    color: var(--color-dark-gray)
}

.TwoFactorItems:nth-child(3) .TwoFactorLink {
    background: var(--color-dead) url("../img/two-fa/ios.svg") no-repeat right bottom!important;
    font-size: 20px;
    color: var(--color-dark-gray)
}

.TwoFactorLink span {
    width: 160px;
}

.TwoFactorCode {
    border-radius: 9px;
    background: var(--color-dead);
    color: var(--color-green);
    font-size: 20px;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 15px;
}

.TWoFaForm > span {
    color: var(--color-dark-gray);
    display: block;
    font-size: 20px;
    margin-bottom: 70px;
}

.TWoFaForm > strong {
    color: var(--color-gray);
    display: block;
    margin-bottom: 35px;
    font-size: 20px;
}

.TwoFactorBackup {
    border-top: 1px solid var(--color-silver);
    margin-top: 80px;
    padding-top: 60px;
}

.TwoFactorBackup > span {
    display: block;
    font-size: 20px;
}

.TwoFactorBackup > p {
    color: var(--color-gray);
    font-size: 20px;
}

.IBSummaryWrapper svg {
    color: var(--color-green)
}

.HeaderProfile {
    font-size: 16px;
}

.HeaderProfile > span {
    font-size: 14px;
}

.UserArea {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.PanelWalletButtons {
    padding: 0 25px;
}

.CloseNav {
    display: none;
}

.WalletDetails {
    display: flex;
    padding: 35px 25px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.DepositMethodIcon {
    display: flex;
}

.DepositDetailsItems li bdi,
.TicketTime {
    display: none;
}

.TicketTime {
    color: var(--color-gray);
    margin-left: 20px;
}

.ChangePassModal label {
    width: 180px;
}

.ModalInput {
    width: calc(100% - 180px);
}

.ChartWrapper {
    width: 100%;
    height: 350px;
}

.ChangePassModal small {
    color: var(--color-very-light-gray);
    font-size: 14px;
    display: block;
    margin-top: 10px;
}

.AccountTypes {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
}

.AccountTypesBody {
    padding: 15px;
}

.AccountTypesPrice {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;;
    margin-top: 10px;
}

.AccountTypesPrice span {
    font-size: 40px;
    color: var(--color-green);
    line-height: 40px;
    font-weight: bold;
}

.AccountTypesPrice p {
    width: 100%;
    color: var(--color-very-light-gray);
    font-size: 15px;
    margin: 15px 0 0 0;
    padding: 0;
}

.AccountTypesPrice bdi {
    font-size: 20px;
    color: var(--color-green);
    display: block;
    margin-right: 3px;
    font-weight: bold;
}

.AccountTypesTitle {
    background: #F8F8F8;
    color: var(--color-green);
    padding: 15px;
    display: flex;
    align-items: center;
}

.AccountTypesButton,
AccountTypesButton:hover {
    background: linear-gradient(270deg, #00B991 12.99%, #00D37E 83.99%);
    color: white!important;
    font-size: 16px;
}

.AccountTypesTitleSpecial {
    background: linear-gradient(270deg, #00B991 12.99%, #00D37E 83.99%)!important;
    color: white!important;
}

.AccountTypesTitle strong {
    font-size: 19px;
}

.AccountTypesTitle span {
    font-size: 15px;
    display: block;
    margin-left: 5px;
}

.AccountTypesAmount {
    display: flex;
}

.AccountTypeSocial {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.AccountTypeVIP {
    background: var(--color-green);
    color: white;
    font-size: 12px;
    padding: 3px;
    border-radius: 3px;
    margin-left: 5px;
}

.AccountTypeFeatures {
    border-top: 1px solid var(--color-silver);
    padding-top: 15px;
    margin: 15px 0 25px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.AccountTypeFeaturesList {
    margin: 10px 0 0 0;
    padding: 0 0 0 10px;
    list-style: none;
    color: var(--color-very-light-gray);
    font-size: 16px;
}

.AccountTypeFeaturesList li {
    margin: 0 0 6px 0;
}

.AccountTypeFeaturesList li::before {
    content: "";
    background: var(--color-very-light-gray);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.AccountTypesDemo {
    color: var(--color-green);
    font-size: 15px;
    margin-bottom: 25px;
}


.AccountTypeFeatures span {
    background: #F8F8F8;
    border-radius: 15px!important;
    color: var(--color-gray)!important;
    font-size: 15px!important;
    padding: 5px 15px!important;
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.TradersBoxWrapper {
}

.Top10Box1 {
    background: #575757;
    border-radius: 15px;
    height: 100%;
}

.Top10Box2 {
    background: #3ebaaf;
    border-radius: 15px;
    height: 100%;
}

.Top10BoxInside {
    padding: 20px;
}

.CrownIcon {
    background: url("../../assets/img/top10/crown.svg") no-repeat;
    width: 37px;
    height: 37px;
    display: flex;
    background-size: contain;
}

.Top10Box1 h5,
.Top10Box2 h5 {
    color: white;
    font-size: 70px;
    font-family: "RubikBold";
    margin: 0;
    line-height: 50px;
    text-transform: uppercase;
    word-break: break-word;
}

.Top10Box1 p,
.Top10Box2 p {
    color: white;
    font-size: 18px;
    line-height: 30px;
    min-height: 95px;
}

.Top10Box1 span {
    color: #575757;
    background: #FFB743;

}

.Top10Box2 span {
    color: #3EBAAF;
    background: white;
}

.Top10Box1 span,
.Top10Box2 span {
    font-size: 20px;
    border-radius: 10px;
    padding: 2px;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: bold;
    margin: 10px 0 0 0;
    padding: 5px;
}

.TopBoxWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* RTL */

.RtlStyle {
    direction: rtl;
}

.RtlStyle .AccountTypeFeaturesList li::before {
    margin-right: unset;
    margin-left: 10px;
}

.RtlStyle .AccountTypesAmount,
.RtlStyle .AccountTypesTitle {
    flex-direction: row-reverse;
}

.RtlStyle .AccountTypesTitle {
    justify-content: flex-start;
}

.RtlStyle .SideBar {
    right: 0;
    left: unset;
}

.RtlStyle .SwitchLang {
    margin-right: unset;
    margin-left: 15px;
}

.RtlStyle .SwitchLang span,
.RtlStyle .WalletListAmount bdi {
    margin-left: unset;
    margin-right: 10px;
}

.RtlStyle .SwitchTheme {
    margin-left: 22px;
    margin-right: auto;
}

.RtlStyle .PanelBoxWrapper {
    background: linear-gradient(-270deg, #EEF1F7 19.92%, rgba(238, 241, 247, 0.00) 79.84%);
}

.RtlStyle .HeaderButtons a {
    margin-left: unset;
    margin-right: 30px;
}

.RtlStyle .PanelBoxIcon {
    border-right: unset;
    margin-right: unset;
    padding-right: unset;

    border-left: 2px solid #DBDBDB;
    margin-left: 30px;
    padding-left: 30px;
}

.RtlStyle .DepositMethodButtons button:last-child {
    margin-left: unset;
    margin-right: 25px;
}

.RtlStyle .DepositMethods > label {
    margin-right: unset;
    margin-left: 15px;
}

.RtlStyle .DepositMethodForm label {
    margin-right: unset;
    margin-left: 25px;
}

.RtlStyle .AccountTable li bdi {
    margin-left: auto;
    margin-right: inherit;
}

.RtlStyle .AccountsOverviewDetails bdi {
    text-align: right;
}

.RtlStyle .PerformanceDate span {
    margin-right: unset;
    margin-left: 20px;
}

.RtlStyle .PerformanceDate strong {
    margin-left: 20px;
}

.RtlStyle .PerformanceSetting {
    margin-left: 20px;
}

.RtlStyle .LinksForm label {
    margin-right: unset;
    margin-left: 10px;
}

.RtlStyle .Preferences > span {
    margin-left: unset;
    margin-right: 10px;
}

.RtlStyle .TicketAuthorName {
    margin-left: unset;
    margin-right: 20px;
}

.RtlStyle .userDot time, .RtlStyle .adminDot time {
    left: unset;
    right: -110px;
}

.RtlStyle .TicketReply {
    margin-left: unset;
    margin-right: 80px;
}

.RtlStyle .TicketTimeline {
    margin-left: unset;
    margin-right: 100px;
}

.RtlStyle .PanelBox h4 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
}

.RtlStyle .PanelBox h4 i {
    margin-right: 10px;
}

.RtlStyle .SideBarLogo {
    padding-left: unset;
    padding-right: 40px;
}

.RtlStyle .HelpDeskForm label,
.RtlStyle .IBForm label {
    margin-right: unset;
    margin-left: 32px;
}

.RtlStyle .DepositItem bdi {
    margin-right: 10px;
    margin-left: auto;
}

.RtlStyle .DepositMethodItem li img {
    margin-right: inherit;
    margin-left: auto;
}

.RtlStyle .DepositItem h5 {
    margin: 0 0 0 auto;
}

.RtlStyle .PerformanceSetting,
.RtlStyle .AccountManagerSocial li span,
.RtlStyle .PerformanceDate strong {
    margin-left: unset;
    margin-right: 20px;
}

.RtlStyle .PerformanceSetting::before {
    margin-right: unset;
    margin-left: 20px;
}

.RtlStyle .TwoFactorLink {
    padding-left: unset;
    padding-right: 25px;
}

.RtlStyle .TwoFactorItems:nth-child(1) .TwoFactorLink,
.RtlStyle .TwoFactorItems:nth-child(2) .TwoFactorLink,
.RtlStyle .TwoFactorItems:nth-child(3) .TwoFactorLink {
    background-position: left bottom!important;
}

.RtlStyle .MobileNav {
    margin-right: 15px;
    margin-left: unset;
}

.RtlStyle .AccountModalReviewCopy {
    margin-left: unset;
    margin-right: 30px;
}

.RtlStyle .Top10Box1,
.RtlStyle .Top10Box2 {
    direction: ltr;
}

.RtlStyle .Top10Box1 p,
.RtlStyle .Top10Box2 p {
    text-align: right;
    direction: rtl;
}

.RtlStyle .TradersBox span {
    font-family: "Rubik"!important;
}

@media all and (max-width: 1368px) {
    .CopyButton,
    .ChangeButton {
        display: flex;
        justify-content: flex-end;
        width: 100%!important;
    }
    .AccountTable li,
    .AccountTable li > span,
    .AccountTable li bdi {
        display: block;
    }
}


@media all and (min-width: 992px) and (max-width: 1440px){
    .PanelWalletButtons {
        padding: 0 5px;
    }
    .SideBarWrapper,
    .SideBar{
        width: 245px;
    }
    .MainContent {
        width: calc(100% - 245px);
    }
    .PanelWrapper,
    .PanelHeader,
    .AuthForm {
        padding: 0 35px;
    }
    .HeaderButton,
    .HeaderProfile > span,
    .AuthNotRegister p,
    .AuthForm label,
    .AuthForm a,
    .AuthTerms div {
        font-size: 14px;
    }
    .HeaderProfile,
    .LinksTable thead tr th,
    .LinksTable tbody tr td,
    .AccountManagerModal p,
    .AccountManagerSocial li span,
    .DepositMethodForm small,
    .DepositMethods small,
    .DepositMethodButtons button,
    .PaymentDetailsUpload small,
    .AuthNotRegister > span,
    .DepositItemList > span,
    .AccountsOverview table tr th,
    .AccountsOverviewBox li > span,
    .Platform p,
    .HeaderButtons a,
    .OverviewTable tbody tr td,
    .AccountTypesPrice bdi {
        font-size: 16px;
    }
    .DepositItem bdi,
    .PanelBox h3,
    .AccountsOverview table tr td,
    .AccountsOverviewBox li bdi,
    .DownloadBox span,
    .DepositItem h5,
    .QuickLinks h5 {
        font-size: 17px!important;
    }
    .PanelBox h4,
    .AccountModalHeader span,
    .AccountModalHeader p,
    .PanelBoxWrapper h6,
    .DepositItem span,
    .DepositNotes h5,
    .DepositNotes h6,
    .DepositNotes,
    .DepositMethodForm input,
    .DepositMethodForm label,
    .DepositReview li bdi,
    .DepositReview li span,
    .DepositReview strong,
    .DepositSuccess,
    .WithdrawSuccess span,
    .PanelBoxDetails h6,
    .PanelBoxDetails label,
    .DepositMethods > label,
    .AuthForm h6,
    .AccountModalField label,
    .Platform h6,
    .AccountTypesTitle strong {
        font-size: 19px;
    }
    .DashboardSection h6,
    .Main h1,
    .Main h2 {
        font-size: 18px;
    }
    .PanelWallet span,
    .WalletListAmount {
        font-size: 21px;
    }
    .AuthSlogan bdi,
    .AccountTypesPrice span {
        font-size: 23px;
    }
}

@media all and (min-width: 992px) and (max-width: 1100px){
    .HeaderButtons {
        display: none;
    }
}

@media all and (max-width: 1200px){
    .Top10Box1 span,
    .Top10Box2 span {
        margin-left: unset;
        font-size: 22px;
    }
    .Top10Box1 h5,
    .Top10Box2 h5 {
        word-break: break-all;
        font-size: 49px;
    }
    .Top10Box1 p,
    .Top10Box2 p {
        font-size: 17px;
    }
    .TradersBox span {
        font-size: 16px;
    }
}

@media all and (min-width: 992px){
    .MobileNav,
    .MobileLogo {
        display: none;
    }
}

@media all and (max-width: 991px){
    .NoGutter {
        margin: 0 !important;
        row-gap: 20px!important;
    }
    .ChangePassModal label,
    .ModalInput {
        width: 100%;
    }
    .CopyButton,
    .ChangeButton {
        display: flex;
        justify-content: flex-end;
        width: 100%!important;
    }
    .PanelBoxIcon,
    .LoginHeader,
    .DepositHead,
    .HeaderButtons {
        display: none;
    }
    .DepositItemList > bdi,
    .AccountModalField,
    .TicketTime,
    .TicketAttachment a,
    .AccountModalField label,
    .ChangeForm label,
    .TWoFaForm label {
        display: block!important;
    }
    .DepositMethodItem li {
        flex-wrap: wrap;
        padding: 10px 0;
        display: block;
    }
    .CloseNav {
        display: block;
        color: white;
        font-size: 22px;
    }
    .MobileNav {
        margin-left: 15px;
    }
    .SideBarWrapper {
        position: fixed;
        z-index: 999;
    }
    .SideBar {
        left: -345px;
        transition: .5s all ease;
    }
    .SideBarActive {
        left: 0!important;
    }
    .MainContent,
    .WalletDetails > div,
    .AuthSider,
    .AuthSider60,
    .AuthSider40,
    .DepositMethodItem li,
    .AccountModalFields,
    .AccountModalReview {
        width: 100%;
    }
    .DepositItemList {
        flex-wrap: nowrap;
        margin-bottom: 10px;
        width: 100%;
    }
    .DepositItem,
    .PanelBoxWrapper {
        width: auto!important;
    }
    .PanelWrapper,
    .PanelWalletButtons,
    .WalletDetails,
    .AuthForm,
    .ChangeForm {
        padding: 0 15px;
    }
    .PanelHeader {
        height: auto;
        padding: 10px 15px;
    }
    .DocumentList img {
        width: 50px;
    }
    .DashboardSection h6 {
        margin: 10px 0;
    }
    .QuickLinks h5 {
        width: calc(100% - 43px);
    }
    .QuickLinksIconBox {
        width: 43px;
        height: 67px;
    }
    .QuickLinksIconBox img {
        width: 18px;
    }
    .SwitchLang {
        margin-right: 15px;
    }
    .UserArea,
    .HeaderLinks {
        justify-content: space-between;
        width: 100%;
    }
    .SwitchTheme {
        width: 28px;
        height: 28px;
        margin-left: auto;
        margin-right: 15px;
    }
    .SwitchTheme img {
        width: 21px;
    }
    .QuickDeposit {
        height: 30px!important;
    }
    .QuickDeposit img {
        width: 18px;
    }
    .PanelBoxPadding,
    .PanelWrapperPadding,
    .PanelBoxTicket {
        padding: 15px;
    }
    .PanelWallet {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .PanelWallet span {
        margin-left: 10px;
    }
    .SideBarLogo {
        height: auto;
        padding: 15px;
    }
    .Preferences {
        margin-right: 10px;
    }
    .MobileBackDrop {
        position: fixed;
        left: 0;
        top: 0;
        background: rgb(0 0 0 / 50%);
        width: 100%;
        height: 100%;
        backdrop-filter: blur(2px);
        opacity: 0;
        visibility: hidden;
    }
    .MobileBackDropActive {
        opacity: 1!important;
        visibility: visible!important;
        transition: .5s all ease;
    }

    .WalletDetails > div {
        margin-top: 10px;
    }
    .OverviewTag {
        margin-top: 10px;
        display: block;
        white-space: break-spaces;
        border-radius: 5px;
        padding: 5px;
    }
    .DepositMethodItem li img {
        width: 30px;
    }
    .PaymentDetailsUpload {
        margin-top: 15px;
    }
    .DepositItem bdi {
        margin-right: unset;
    }
    .AuthSplash img {
        height: 250px;
    }
    .AuthSplash {
        padding-top: 30px;
    }
    .AuthNotRegister p {
        margin: 25px 0;
    }
    .AuthNotRegister {
        margin-top: 30px;
        padding-top: 30px;
    }
    .AuthRegSplash img {
        width: 220px;
    }
    .AuthRegSplash {
        height: 170px;
    }
    .AuthRegSplash::before {
        background-position: center;
        background-size: contain;
        top: 10px;
    }
    .DepositMethodIcon img {
        width: 20px;
    }
    .SwitchLang img {
        width: 25px;
    }
    .AccountTable li,
    .AccountTable li > span,
    .AccountTable li bdi {
        display: block;
    }
    .AccountTable li bdi {
        margin: 10px 0
    }
    .TicketAuthor {
        margin-left: 10px;
    }
    .TicketTimeline,
    .TicketReply {
        margin-left: 0px;
    }
    .TicketText,
    .TicketUser {
        padding-left: 0px;
    }
    .PerformanceItem,
    .TwoFactorItems:nth-child(1) .TwoFactorLink,
    .TwoFactorItems:nth-child(2) .TwoFactorLink,
    .TwoFactorItems:nth-child(3) .TwoFactorLink{
        margin-bottom: 20px;
    }
    .DocumentVersion a img {
        width: 30px;
    }
    .TicketAttachment a,
    .AccountModalField label,
    .ChangeForm label,
    .TWoFaForm label {
        margin-bottom: 10px;
    }
    .userDot time,
    .adminDot time {
        display: none;
    }
    .TwoFactorCode bdi {
        word-break: break-all;
    }
    .TwoFactorBackup {
        margin-top: 15px;
        padding-top: 15px;
    }
    .HeaderButton,
    .HeaderProfile > span,
    .AuthNotRegister p,
    .AuthForm label,
    .AuthForm a,
    .AuthTerms div,
    .TicketTime,
    .TicketText p {
        font-size: 12px;
    }
    .HeaderProfile,
    .LinksTable thead tr th,
    .LinksTable tbody tr td,
    .AccountManagerModal p,
    .AccountManagerSocial li span,
    .DepositMethodForm small,
    .DepositMethods small,
    .DepositMethodButtons button,
    .PaymentDetailsUpload small,
    .AuthNotRegister > span,
    .DepositItemList > span,
    .AccountsOverview table tr th,
    .AccountsOverviewBox li > span,
    .Platform p,
    .TicketAuthorName,
    .LinksForm small,
    .HelpDeskTable table thead tr th,
    .AccountTable li bdi,
    .AccountTypesTitle span {
        font-size: 14px!important;
    }
    .DepositItem bdi,
    .PanelBox h3,
    .AccountsOverview table tr td,
    .AccountsOverviewBox li bdi,
    .DownloadBox span,
    .AccountVerified span,
    .DocumentList,
    .DocumentList:hover,
    .HelpDeskTable table tbody tr td {
        font-size: 15px!important;
    }
    .PanelBox h4,
    .QuickLinks h5,
    .OverviewTable tbody tr td,
    .AccountModalHeader span,
    .AccountModalHeader p,
    .PanelBoxWrapper h6,
    .DepositItem span,
    .DepositNotes h5,
    .DepositNotes h6,
    .DepositNotes,
    .DepositMethodForm input,
    .DepositMethodForm label,
    .DepositReview li bdi,
    .DepositReview li span,
    .DepositReview strong,
    .DepositSuccess,
    .WithdrawSuccess span,
    .PanelBoxDetails h6,
    .PanelBoxDetails label,
    .DepositMethods > label,
    .AuthForm h6,
    .AccountModalField label,
    .Platform h6,
    .DepositItem h5,
    .LinksForm label,
    .TwoFactorItems:nth-child(1) .TwoFactorLink,
    .TwoFactorItems:nth-child(2) .TwoFactorLink,
    .TwoFactorItems:nth-child(3) .TwoFactorLink,
    .TWoFaForm > span,
    .TWoFaForm > strong,
    .TWoFaForm label,
    .TwoFactorCode,
    .HelpDeskForm label,
    .IBForm label,
    .HelpDeskForm small,
    .HeaderButtons a,
    .TwoFactorBackup > p,
    .TwoFactorBackup > span,
    .HelpDeskEmpty span,
    .ChangeForm label,
    .TWoFaForm labe,
    .MessagesEmpty span,
    .AccountTypesTitle strong,
    .AccountTypesPrice bdi {
        font-size: 16px!important;
    }
    .DashboardSection h6,
    .Main h1,
    .Main h2 {
        font-size: 18px;
    }
    .PanelWallet span,
    .WalletListAmount {
        font-size: 21px;
    }
    .AuthSlogan bdi,
    .AccountTypesPrice span {
        font-size: 23px;
    }
    .PanelWallet span {
        margin-left: unset!important;
        margin-right: 10px;
    }

    .RtlStyle .SideBar {
        left: unset!important;
        right: -345px!important;
    }

    .RtlStyle .SideBarActive {
        left: unset!important;
        right: 0!important;
    }
    .Top10Box2 {
        margin-top: 15px;
    }
}

@media all and (max-width: 767px){

}

@media all and (max-width: 360px){
    .SideBarWrapper,
    .SideBar {
        width: 80%;
    }
}